.allianceBox {
  padding: 30px 20px;
  box-shadow: -1px 0px 20px #e4e5e6;
  border-radius: 15px;
  margin-bottom: 5rem;
  margin-left: 15px;
  margin-right: 15px;
}
.allianceBox img {
  padding-bottom: 10px;
  width: 100%;
}
.allianceBox h3 {
  font-weight: 600;
  color: #446b4f;
}
.partner {
  text-align: center;
}
.partner img {
  margin-bottom: 5vh;
  height: 10rem;
  border-radius: 10px;
}
@media screen and (max-width: 768px) {
  .partner img {
    width: 100%;
  }
}
