.homeBox {
  display: flex;
  min-height: 75vh;
  align-items: center;
  flex-wrap: wrap;
}
.homeBox .homeDisplay {
  height: 100%;
  background: rgb(24, 88, 187);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  flex-wrap: wrap;
  font-size: 30px;
  padding-bottom: 20px;
  background-size: cover;
  min-height: 75vh;
}
