html {
  scroll-behavior: smooth;
}
.navWrapper {
  width: 100%;
  float: left;
  background: #f8f9f7;
}
.navBox {
  height: 80px;
  padding-top: 19px;
}
.logoWrapper {
  float: left;
  line-height: 4rem;
  /*width: 90px;
  max-height: 62px;*/
  color: #446b4f;
  font-size: 1.65rem;
  font-weight: 500;
}
.logoWrapper img {
  width: 80%;
  margin: -17px auto auto 5px;
}
.navMenu {
  float: right;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 45px;
}
.supsenseLoader {
  text-align: center;
  margin-top: 50%;
  margin-bottom: 50%;
}
.menu-wrap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}
.menu-wrap .menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.menu-wrap .menu > div {
  background-color: rgba(24, 39, 51, 0.85);
  border-radius: 50%;
  width: 200vw;
  height: 200vw;
  display: flex;
  flex: none;
  align-items: center;
  justify-content: center;
  transform: scale(0);
  transition: all 0.4s ease;
}
.menu-wrap .menu > div > div {
  text-align: center;
  max-width: 90vw;
  max-height: 100vh;
  opacity: 0;
  transition: opacity 0.4s ease;
}
.menu-wrap .menu > div > div > ul > li {
  list-style: none;
  color: #fff;
  font-size: 1.5rem;
  padding: 1rem;
}
.menu-wrap .menu > div > div > ul > li > a {
  color: inherit;
  text-decoration: none;
  transition: color 0.4s ease;
}
.service-item {
  text-align: center;
}
.service-item .icon {
  width: 110px;
  height: 110px;
  display: inline-block;
  text-align: center;
  line-height: 104px;
  border: 3px solid #fff;
  border-radius: 50%;
  background-color: #f9a309;
}
.service-item .primary-blue-button {
  margin-top: 35px;
  margin-bottom: 65px;
}
.primary-blue-button label {
  display: inline-block;
  background-color: #446b4f;
  font-size: 13px;
  padding: 12px 18px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 20px;
}
.service-item i {
  color: #fff;
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
}
.service-item h4 {
  margin-bottom: 20px;
  margin-top: 25px;
  font-size: 17px;
  font-weight: 600;
  color: #000;
  letter-spacing: 1px;
}
.service-item .line-dec {
  width: 100%;
  height: 1px;
  background-color: #000;
}
.service-item p {
  color: #000;
  margin-top: 15px;
  height: 15vh;
  line-height: 24px;
  font-size: 1.5rem;
}
.service-item .primary-blue-button {
  margin-top: 35px;
}
.service-info i {
  padding: 10px;
}
.modal {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: left;
  background: rgba(0, 0, 0, 0.9);
  transition: opacity 0.25s ease;
}
.modal img {
  /* width: 60%; */
  border-radius: 50%;
  width: 25rem;
  height: 25rem;
  object-fit: cover;
}
.modal .topic {
  padding-top: 1em;
  margin-bottom: 0;
}
.modal h3 {
  line-height: 3.7vh;
  font-size: 15px;
  text-align: left;
  color: #758c92;
  margin-bottom: 0;
  padding-bottom: 25px;
}
.modal__bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
}
.modal-state {
  display: none;
}
.modal-state:checked + .modal {
  opacity: 1;
  visibility: visible;
  z-index: 2;
}
.modal-state:checked + .modal .modal__inner {
  top: 10%;
}
.modal__inner {
  transition: top 0.25s ease;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: 80%;
  margin: auto;
  overflow: auto;
  background: #fff;
  border-radius: 5px;
  height: 80%;
}
.modal__close {
  position: absolute;
  right: 1em;
  top: 1em;
  width: 1.1em;
  height: 1.1em;
  cursor: pointer;
  z-index: 2;
}
.modal__close:after,
.modal__close:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 1.5em;
  background: #ccc;
  display: block;
  transform: rotate(45deg);
  left: 50%;
  margin: -3px 0 0 -1px;
  top: 0;
}
.modal__close:hover:after,
.modal__close:hover:before {
  background: #aaa;
}
.modal__close:before {
  transform: rotate(-45deg);
}
@media screen and (max-width: 768px) {
  .modal__inner {
    width: 90%;
    height: 80%;
    box-sizing: border-box;
  }
  .connectFeatures {
    height: max-content;
  }
.logoWrapper {
  float: left;
    line-height: 1;
    width: 90px;
    max-height: 40px;
}
.logoWrapper img {
  width: 83%;
  margin: -17px auto auto 5px;
}
}
@media screen and (min-width: 768px) {
  .largeScreenPadding {
    padding: 0 110px;
  }
}
#ContactUs .contact-title {
  font-size: 30px;
  color: #000;
  text-align: center;
  margin: 0 0 30px;
}
#ContactUs .contact-outer-wrapper {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  margin: 0 auto;
}
#ContactUs .add-title {
  font-size: 22px;
  font-weight: 700;
  margin: 0 0 50px;
  text-align: center;
}
#ContactUs .form-wrap {
  display: table-cell;
  padding: 40px;
}
#ContactUs .form-wrap .floating-label {
  position: relative;
  width: 47%;
  display: inline-block;
  margin-bottom: 30px;
}
#ContactUs .form-wrap .contact,
#ContactUs .form-wrap .fname {
  margin-right: 20px;
}
#ContactUs .form-wrap .user-msg {
  width: 98%;
  display: block;
  margin-bottom: 40px;
}
#ContactUs .floating-input {
  width: 100%;
  border: 0;
  border-bottom: 1px solid #999;
  box-shadow: none;
  background-color: transparent;
  padding-top: 3px;
}
#ContactUs .floating-input:focus {
  outline: 0;
}
#ContactUs .form-wrap label {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 14px;
  text-align: left;
  color: #999;
  transition: top 0.2s ease-in-out;
  z-index: -1;
  margin: 0;
}
#ContactUs .floating-input:focus ~ label {
  top: -18px;
  font-size: 12px;
  color: #0098aa;
  transition: top 0.2s ease-in-out;
}
#ContactUs .submit-btn {
  text-align: center;
}
#ContactUs .submit-btn button {
  font-size: 16px;
  border: 0;
  border-radius: 0;
  background-color: #0098aa;
  color: #fff;
  padding: 10px 20px;
  cursor: pointer;
  outline: 0;
}
#ContactUs .submit-btn button:hover {
  background-color: rgba(0, 152, 170, 0.8);
}
.farmsImage {
  width: 100%;
  padding-top: 25px;
  padding-bottom: 25px;
}
.connectFeatures {
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 30px;
  font-size: 16px;
  height: 22em;
  text-align: initial;
}
.titleInfo {
  padding: 25px;
  text-align: center;
  font-size: 3rem;
  font-weight: 600;
  line-height: 40px;
}
.footerBox a {
  color: #ffffff;
}
.footerBox a:hover {
  color: #f9a309;
}
